import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FileUploadDialogContent, IconName } from '@san/tools/models';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { SanUploadDialogComponent } from '@san/tools/ui/file-upload';
import { BaseComponent } from '@san/tools/utils';
import { filter, map, take, takeUntil } from 'rxjs';
import { PatientService } from '../../api/patient.service';
import { ProService } from '../../api/pro.service';
import { ImageDirective } from '../../directives/image.directive';
import { ApiResponse } from '../../models/dto/response.dto';
import { Utilisateur } from '../../models/entity/Utilisateur';
import { Patient } from '../../models/entity/patient';
import { AppConf } from '../../models/interfaces/app-conf.interface';
import {
  FileDataResponse,
  FileDestination,
} from '../../models/interfaces/file.interface';
import { PreviewFilePipe } from '../../pipes/file.pipe';
import { DialogService } from '../../services/dialog.service';
import { FileService } from '../../services/file.service';
import { ReactiveService } from '../../services/reactive.service';
import { AppconfService } from './../../services/app-conf.service';

@Component({
  selector: 'rdv-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FlexLayoutModule,
    PreviewFilePipe,
    SanButtonIconComponent,
    ImageDirective,
  ],
})
export class PhotoComponent extends BaseComponent {
  private readonly proService = inject(ProService);
  private readonly reactiveService = inject(ReactiveService);
  private readonly patientService = inject(PatientService);
  private readonly dialogService = inject(DialogService);
  private readonly appConfService = inject(AppconfService);

  @Input() user: Utilisateur | Patient;
  @Input() isPatient: boolean = true;
  @Input() editable: boolean = false;
  @Output() photoChange: EventEmitter<Utilisateur | Patient> = new EventEmitter<
    Utilisateur | Patient
  >();
  readonly IconName = IconName;
  readonly conf: AppConf = this.appConfService.getAppConf();

  get destitation() {
    return this.isPatient ? FileDestination.PATIENT : FileDestination.USER;
  }

  openEditionModal() {
    this.dialogService
      .open(SanUploadDialogComponent, {
        data: {
          header: 'shared.photo',
          content: {
            extensions: this.appConfService.imageExtensions,
            uploadFn: (files: File[]) => {
              const data = new FormData();
              data.append('uuid', this.user.id);
              data.append('destination', this.destitation);
              data.append('photo', files[0], files[0].name);
              const ws = !this.isPatient
                ? this.proService.updatePhoto(data)
                : this.patientService.updatePhoto(data);
              return ws.pipe(filter(FileService.filterFileResponse));
            },
          } as FileUploadDialogContent,
        },
      })
      .closed.pipe(
        takeUntil(this.destroy),
        take(1),
        filter((res) => !!res?.content),
        map(
          (res) =>
            (res.content as { response: ApiResponse<FileDataResponse> })
              ?.response?.data as FileDataResponse
        )
      )
      .subscribe((file) => {
        this.user.fichier = file.name;
        this.photoChange.next(this.user);
      });
  }

  deletePhoto() {
    if (this.user?.fichier?.length) {
      const ws = !this.isPatient
        ? this.proService.deletePhoto(this.user.id)
        : this.patientService.deletePhoto(this.user.id);
      this.reactiveService.call(
        ws.pipe(take(1)),
        () => {
          this.user.fichier = null;
          this.photoChange.next(this.user);
        },
        true
      );
    }
  }
}
