import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  IconName,
  Page,
  SearchCondition,
  SearchParam,
  SortDirection,
} from '@san/tools/models';
import { SanAutocompleteComponent } from '@san/tools/ui/autocomplete';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { SanModalComponent } from '@san/tools/ui/modal';
import { FilterUtils } from '@san/tools/utils';
import { debounceTime, map } from 'rxjs';
import { EtablissementService } from '../../api/etablissement.service';
import { RdvService } from '../../api/rdv.service';
import { Medecin } from '../../models/entity/medecin';
import { Rdv } from '../../models/entity/rdv';
import { RdvType } from '../../models/enum/rdv.enum';
import {
  DisplayDatePipe,
  DisplayPricePipe,
  DisplayValuePipe,
} from '../../pipes/object.pipe';
import { RdvStatusPipe } from '../../pipes/rdv.pipe';
import { FullNamePipe } from '../../pipes/user.pipe';
import { ReactiveService } from '../../services/reactive.service';
import { UserService } from './../../services/user.service';

@Component({
  selector: 'rdv-medecin-dialog',
  templateUrl: './rdv-medecin-dialog.component.html',
  styleUrls: ['./rdv-medecin-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    SanModalComponent,
    SanAutocompleteComponent,
    SanButtonIconComponent,
    RdvStatusPipe,
    DisplayValuePipe,
    DisplayDatePipe,
    DisplayPricePipe,
    TranslatePipe,
    FullNamePipe,
  ],
})
export class RdvMedecinDialogComponent
  extends BaseDialogComponent
  implements OnInit
{
  private readonly etablissementService = inject(EtablissementService);
  private readonly rdvService = inject(RdvService);
  private readonly reactiveService = inject(ReactiveService);

  readonly IconName = IconName;
  rdv: Rdv;
  formGroup: FormGroup;
  param: SearchParam = {
    relations: ['utilisateur.etablissement'],
    orders: [
      { field: 'utilisateur.nom', direction: SortDirection.ASC },
      { field: 'utilisateur.prenoms', direction: SortDirection.ASC },
    ],
  };

  constructor() {
    super();
    this.rdv = this.data?.content as Rdv;
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      medecin: new FormControl(this.getSelectedMedecin(), Validators.required),
    });
  }

  filterMedecins = (term: string, limit?: number) => {
    const option = FilterUtils.generateFilter(SearchCondition.AND);
    const utils = new FilterUtils();
    option.filters.push(utils.stringFilter('actif').equal(true).get());
    option.filters.push(
      utils.stringFilter('utilisateur.verifie').equal(true).get()
    );
    option.filters.push(
      utils.stringFilter('utilisateur.actif').equal(true).get()
    );

    const subFilter = FilterUtils.generateSubFilter(SearchCondition.OR);
    subFilter.subFilters.push(
      utils.stringFilter('utilisateur.nom').contains(term).get()
    );
    subFilter.subFilters.push(
      utils.stringFilter('utilisateur.prenoms').contains(term).get()
    );
    option.filters.push(subFilter);

    this.param.filter = option;
    this.param.page = Page.build({ pageSize: limit, currentPage: 1 });

    return this.etablissementService.getMedecins(this.param).pipe(
      debounceTime(500),
      map((resp) =>
        resp.data.map((m) => ({
          value: m.id,
          label: UserService.getFullName(m.utilisateur),
          data: m,
        }))
      )
    );
  };

  getSelectedMedecin() {
    const medecin = this.rdv?.medecin;
    return medecin
      ? {
          value: medecin.id,
          label: UserService.getFullName(medecin.utilisateur),
          data: medecin,
        }
      : null;
  }

  get isRdv() {
    return !!this.rdv?.patient?.id;
  }

  get type() {
    return this.isRdv ? RdvType.RDV : RdvType.EVENT;
  }

  save() {
    if (this.formGroup.valid) {
      const data = this.formGroup.getRawValue();
      const request: Rdv = {
        id: this.rdv.id,
        medecin: { id: data.medecin.value } as Medecin,
      };
      this.reactiveService.call(
        this.rdvService.save(this.type, request),
        (resp) => this.close({ content: resp.data }),
        true
      );
    }
  }
}
