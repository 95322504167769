<san-modal header="rdv.title" (closeModal)="close()">
  <div content>
    <form [formGroup]="rdvForm">

      <san-button-toggle *ngIf="!(rdv?.id || patient?.id)" idControl="rdv-dialog-type" label="rdv.type"
        formControlName="type" [options]="types" [manageObject]="false"></san-button-toggle>
      <br>
      <div *ngIf="medecinUser" class="info">
        <div><label>{{'shared.medecin' | translate}}</label> : <span>{{medecinUser | fullName}}</span></div>
      </div>

      <san-information *ngIf="!medecinUser" message="rdv.aucunMedecin" type="warn"
        [icon]="IconName.WARNING"></san-information>

      <san-autocomplete [class.hidden]=" !isRdv" idControl="rdv-dialog-patient" label="rdv.patient"
        placeholder="rdv.patient-pl" formControlName="patient" [clearable]="true" [nbResult]="10"
        [filterFn]="filterPatients" [manageObject]="true"></san-autocomplete>

      <san-select [class.hidden]="!isRdv" idControl="rdv-dialog-prestation" label="rdv.prestation"
        formControlName="prestation" placeholder="rdv.prestation-pl" [options]="prestations$ | async"
        [manageObject]="true"></san-select>

      <san-input type="text" [class.hidden]="isRdv && (!rdv || rdv?.prestation?.id)"
        [label]="isRdv ? 'rdv.prestation' : 'shared.libelle'" [maxLength]="150" formControlName="motif"
        idControl="rdv-dialog-prestation-text">
      </san-input>

      <san-date label="rdv.debut" formControlName="debut" [dateOnly]="false" idControl="rdv-dialog-debut">
      </san-date>

      <san-date *ngIf="!isRdv" label="rdv.fin" formControlName="fin" [dateOnly]="false" idControl="rdv-dialog-fin">
      </san-date>

      <san-input [class.hidden]="isRdv" type="text" label="rdv.lieu" placeholder="rdv.lieu-pl" [maxLength]="100"
        formControlName="lieu" idControl="rdv-dialog-lieu">
      </san-input>

      <san-input type="textarea" [cols]="10" [rows]="5" label="rdv.details" placeholder="rdv.details-pl"
        [maxLength]="250" formControlName="details" idControl="rdv-dialog-details">
      </san-input>

    </form>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" footer>
    <san-button-icon color="warn" [icon]="IconName.CLOSE_CIRCLE" idControl="cancel-rdv" label="shared.quitter"
      (onclick)="close()"></san-button-icon>
    <san-button-icon [icon]="IconName.CHECK_CIRCLE" idControl="validate-rdv" [disabled]="rdvForm.invalid"
      label="shared.valider" (onclick)="save()"></san-button-icon>
  </div>
</san-modal>
