import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslatePipe } from '@ngx-translate/core';
import { IconName } from '@san/tools/models';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { BaseDialogComponent } from '@san/tools/ui/dialog';
import { SanModalComponent } from '@san/tools/ui/modal';
import { RdvService } from '../../api/rdv.service';
import { Rdv } from '../../models/entity/rdv';
import { RdvType, StatusRdv } from '../../models/enum/rdv.enum';
import {
  DisplayDatePipe,
  DisplayPricePipe,
  DisplayValuePipe,
} from '../../pipes/object.pipe';
import { RdvStatusPipe } from '../../pipes/rdv.pipe';
import { FullNamePipe } from '../../pipes/user.pipe';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'rdv-detail',
  templateUrl: './rdv-detail.component.html',
  styleUrls: ['./rdv-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FlexLayoutModule,
    SanModalComponent,
    SanButtonIconComponent,
    TranslatePipe,
    FullNamePipe,
    DisplayPricePipe,
    RdvStatusPipe,
    DisplayDatePipe,
    DisplayValuePipe,
  ],
})
export class RdvDetailComponent extends BaseDialogComponent {
  private readonly rdvService = inject(RdvService);
  private readonly userService = inject(UserService);

  isPro = this.userService.proIsConnected();
  rdv: Rdv;
  readonly IconName = IconName;
  readonly StatusRdv = StatusRdv;

  constructor() {
    super();
    this.rdv = this.data?.content as Rdv;
  }

  get userIsAllowed() {
    return (
      this.userService.isEntityManager() ||
      (this.userService.medecinIsConnected() &&
        this.userService.isMe(this.rdv?.medecin?.utilisateur))
    );
  }

  get title() {
    return this.isRdv ? 'rdv.meeting' : 'rdv.event';
  }

  get isRdv() {
    return !!this.rdv?.patient?.id || !this.isPro;
  }

  get pending() {
    return this.rdv?.status === StatusRdv.PENDING;
  }

  get canCancel() {
    if (!this.isPro) {
      return [StatusRdv.ACCEPTED, StatusRdv.PENDING].includes(this.rdv?.status);
    } else {
      return (
        this.userIsAllowed &&
        [StatusRdv.ACCEPTED, StatusRdv.IN_PROGRESS].includes(this.rdv?.status)
      );
    }
  }

  get canEdit() {
    return this.rdv?.status === StatusRdv.ACCEPTED && this.userIsAllowed;
  }

  editStatus(status: StatusRdv) {
    this.rdvService.editStatus(
      this.rdv,
      status,
      this.isRdv ? RdvType.RDV : RdvType.EVENT,
      () => this.close({ content: true })
    );
  }

  edit() {
    this.close({ content: this.rdv });
  }

  delete() {
    this.rdvService.deleteEvent(RdvType.EVENT, this.rdv, () =>
      this.close({ content: true })
    );
  }
}
