<san-modal [header]="title" (closeModal)="close()">
  <div content fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="8px" class="info small-padding">
    <ng-container *ngIf="rdv">
      <div *ngIf="isRdv">
        @if (isPro) {
        <label>{{'rdv.patient' | translate}}</label> :
        <span>{{rdv.patient | fullName}}</span>
        }
      </div>
      @if(rdv?.medecin?.utilisateur){
      <div>
        <label>{{ (isPro ? 'rdv.intervenant' : 'rdv.medecin') | translate}}</label> :
        <span>{{rdv.medecin?.utilisateur | fullName}}</span>
      </div>
      }
      <div *ngIf="isRdv"><label>{{'rdv.prestation' | translate}}</label> :
        <span>{{rdv?.motif ?? (rdv.prestation?.libelle | displayValue)}} ({{rdv.tarif | displayPrice}})</span>
      </div>
      <div *ngIf="!isRdv"><label>{{'rdv.event' | translate}}</label> : <span>{{rdv.motif | displayValue}}</span></div>
      <div><label>{{'rdv.debut' | translate}}</label> : <span>{{rdv.debut | displayDate:true}}</span></div>
      <div *ngIf="isPro">
        <label>{{'rdv.fin' | translate}}</label> : <span>{{rdv.fin | displayDate:true}}</span>
      </div>
      <div><label>{{'rdv.statut' | translate}}</label> : <span>{{rdv.status | rdvStatus}}</span></div>
      <div><label>{{'rdv.lieu' | translate}}</label> : <span>{{rdv.lieu | displayValue}}</span></div>
      <div><label>{{'rdv.details' | translate}}</label> : <span>{{rdv.details | displayValue}}</span></div>
      <div *ngIf="rdv.commentaire">
        <label>{{'shared.comment' | translate}}</label> : <span>{{rdv.commentaire | displayValue}}</span>
      </div>
    </ng-container>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" footer>

    <ng-container *ngIf="isRdv">
      <san-button-icon *ngIf="canCancel" color="warn" [icon]="IconName.CLOSE" idControl="annuler-rdv"
        label="rdv.action.CANCELED" (onclick)="editStatus(StatusRdv.CANCELED)"></san-button-icon>

      <ng-container *ngIf="pending && userIsAllowed">
        <san-button-icon color="warn" [icon]="IconName.CLOSE_CIRCLE" idControl="refuser-rdv" label="rdv.action.REJECTED"
          (onclick)="editStatus(StatusRdv.REJECTED)"></san-button-icon>

        <san-button-icon (onclick)="editStatus(StatusRdv.ACCEPTED)" [icon]="IconName.CHECK_CIRCLE"
          idControl="accepte-rdv" label="rdv.action.ACCEPTED"></san-button-icon>
      </ng-container>
    </ng-container>

    <san-button-icon *ngIf="!isRdv && userIsAllowed" color="warn" [icon]="IconName.DELETE" idControl="supprimer-rdv"
      label="rdv.action.supprimer" (onclick)="delete()"></san-button-icon>

    <san-button-icon *ngIf="canEdit" [icon]="IconName.EDIT" idControl="modifier-rdv" label="shared.modifier"
      (onclick)="edit()"></san-button-icon>

  </div>
</san-modal>
