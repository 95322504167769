<san-modal header="rdv.affectation" (closeModal)="close()">
  <div content>
    <form [formGroup]="formGroup">
      <div content fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="8px" class="info small-padding">
        <div *ngIf="isRdv">
          <label>{{'rdv.patient' | translate}}</label> : <span>{{rdv.patient | fullName}}</span>
        </div>
        @if(rdv?.medecin?.utilisateur){
        <div>
          <label>{{ 'rdv.intervenant' | translate}}</label> : <span>{{rdv.medecin?.utilisateur | fullName}}</span>
        </div>
        }
        <div *ngIf="isRdv"><label>{{'rdv.prestation' | translate}}</label> :
          <span>{{rdv?.motif ?? (rdv.prestation?.libelle | displayValue)}} ({{rdv.tarif | displayPrice}})</span>
        </div>
        <div *ngIf="!isRdv"><label>{{'rdv.event' | translate}}</label> : <span>{{rdv.motif | displayValue}}</span></div>
        <div><label>{{'rdv.debut' | translate}}</label> : <span>{{rdv.debut | displayDate:true}}</span></div>
        <div>
          <label>{{'rdv.fin' | translate}}</label> : <span>{{rdv.fin | displayDate:true}}</span>
        </div>
        <div><label>{{'rdv.statut' | translate}}</label> : <span>{{rdv.status | rdvStatus}}</span></div>
        <div><label>{{'rdv.lieu' | translate}}</label> : <span>{{rdv.lieu | displayValue}}</span></div>
        <div><label>{{'rdv.details' | translate}}</label> : <span>{{rdv.details | displayValue}}</span></div>
        <div *ngIf="rdv.commentaire">
          <label>{{'shared.comment' | translate}}</label> : <span>{{rdv.commentaire | displayValue}}</span>
        </div>
      </div>

      <san-autocomplete idControl="rdv-dialog-medecin" label="rdv.medecin" placeholder="rdv.medecin-pl"
        formControlName="medecin" [clearable]="true" [nbResult]="10" [filterFn]="filterMedecins"
        [manageObject]="true"></san-autocomplete>

    </form>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" footer>
    <san-button-icon color="warn" [icon]="IconName.CLOSE_CIRCLE" idControl="cancel-rdv" label="shared.quitter"
      (onclick)="close()"></san-button-icon>
    <san-button-icon [icon]="IconName.CHECK_CIRCLE" idControl="validate-rdv" [disabled]="formGroup.invalid"
      label="shared.valider" (onclick)="save()"></san-button-icon>
  </div>
</san-modal>
