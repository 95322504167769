import { inject, Pipe, PipeTransform } from "@angular/core";
import { StatusRdv } from "../models/enum/rdv.enum";
import { TraductorService } from "../services/traductor.service";

@Pipe({ name: 'rdvStatus', standalone: true })
export class RdvStatusPipe implements PipeTransform {

  private readonly traductor = inject(TraductorService);

  transform(status: StatusRdv | string): string {
    return this.traductor.translate(`rdv.etat.${status}`);
  }
}
