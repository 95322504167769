import { inject, Injectable } from '@angular/core';
import { ApiResponse } from '../models/dto/response.dto';
import { StatusCode } from '../models/dto/status-code.model';
import { AppConf } from '../models/interfaces/app-conf.interface';
import { FileDataResponse, FileResponse } from '../models/interfaces/file.interface';
import { environment } from './../../../environments/environment';
import { AppconfService } from './app-conf.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private readonly conf: AppConf = inject(AppconfService).getAppConf();

  static filterUploadResponse(response: ApiResponse<FileResponse>): boolean {
    return response.status === StatusCode.OK && response.data?.files.length > 0;
  }

  static filterFileResponse(response: ApiResponse<FileDataResponse>): boolean {
    return response.status === StatusCode.OK && response.data?.name.length > 0;
  }

  public getBaseUrl() {
    if (!environment.production) {
      return environment.serverUrl;
    }
    return this.conf.apiUrl;
  }

  /**
   * Decode le fichier base64 pour affichage
   * @param base64File
   * @returns
   */
  static createFile(base64File: string, type: string): string {
    return base64File ? `data:${type};base64,${base64File}` : null;
  }

  public static convertSize(bytes: number): string {
    return (bytes / 1048576).toFixed(2);
  }
}
