import { inject, Injectable } from "@angular/core";
import { Column, ColumnType, SearchParam } from '@san/tools/models';
import { filter, Observable, take } from 'rxjs';
import { RdvDetailComponent } from '../components/rdv-detail/rdv-detail.component';
import { RdvDialogComponent } from '../components/rdv-dialog/rdv-dialog.component';
import { RdvMedecinDialogComponent } from '../components/rdv-medecin-dialog/rdv-medecin-dialog.component';
import { ApiResponse } from '../models/dto/response.dto';
import { Utilisateur } from '../models/entity/Utilisateur';
import { Patient } from '../models/entity/patient';
import { Rdv } from "../models/entity/rdv";
import { RdvAffectedStatus, RdvType, StatusRdv } from '../models/enum/rdv.enum';
import { CommentType } from '../models/interfaces/comment.interface';
import { RdvStatusRequest } from '../models/request/rdv.request';
import { DialogService } from '../services/dialog.service';
import { NotificationService } from '../services/notifaction.service';
import { ReactiveService } from '../services/reactive.service';
import { TraductorService } from '../services/traductor.service';
import { UserService } from '../services/user.service';
import { DateService } from './../services/date.service';
import { ObjectUtility } from './../utils/object.utils';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export class RdvService extends ApiBaseService {

  private readonly reactiveService = inject(ReactiveService);
  private readonly dialogService = inject(DialogService);
  private readonly traductor = inject(TraductorService);
  private readonly notificationService = inject(NotificationService);
  private readonly userService = inject(UserService);

  public columns = (type: RdvType = RdvType.RDV): Column<Rdv>[] => {
    const cols: Column<Rdv>[] = [
      { field: 'debut', type: ColumnType.DATE, format: 'dd/MM/yyyy T', defaultSort: true },
      { field: 'fin', type: ColumnType.DATE, format: 'dd/MM/yyyy T' }];

    if (type === RdvType.RDV) {
      cols.push({ field: 'patient', sortField: 'patient.nom', displayFn: (rdv: Rdv) => UserService.getFullName(rdv?.patient) });
    }
    cols.push(...[
      { field: type === RdvType.RDV ? 'prestation' : 'event', sortField: 'motif', displayFn: (rdv: Rdv) => rdv.motif ?? '' },
      { field: 'intervenant', sortField: 'utilisateur.nom', displayFn: (rdv: Rdv) => UserService.getFullName(rdv?.medecin?.utilisateur) },
      { field: 'lieu' },
      { field: 'statut', sortField: 'status', displayFn: (rdv: Rdv) => this.traductor.translate(`rdv.etat.${rdv.status}`) }
    ]);
    return cols;
  }

  constructor() {
    super('meetings');
  }

  /**
   * Enregistre ou modifie un rdv ou un event
   * @param type
   * @param request
   * @returns
   */
  save(type: RdvType, request: Partial<Rdv>): Observable<ApiResponse<Rdv>> {
    return this.http.post<ApiResponse<Rdv>>(`${this.baseUrl}/${type}/save`, request);
  }

  /**
   * Enregistre ou modifie un rdv
   * @param type
   * @param request
   * @returns
   */
  book(request: Partial<Rdv>): Observable<ApiResponse<Rdv>> {
    return this.http.post<ApiResponse<Rdv>>(`${this.baseUrl}/book?type=${RdvType.RDV}`, request);
  }

  /**
   * Renvoie un rdv
   * @param id
   * @param type
   * @param relations
   * @returns
   */
  get(type: RdvType, id: string, relations?: string[]): Observable<ApiResponse<Rdv>> {
    return this.http.post<ApiResponse<Rdv>>(`${this.baseUrl}/${type}/find`, { id, relations });
  }

  getEvents(type: RdvType, param: SearchParam, affectedStatus: RdvAffectedStatus = RdvAffectedStatus.MINE): Observable<ApiResponse<Rdv[]>> {
    const query = `?affectedStatus=${affectedStatus}`;
    return this.http.post<ApiResponse<Rdv[]>>(`${this.baseUrl}/${type}/list/events${query}`, param);
  }

  getCalendarEvents(param: SearchParam, showMyEvents: boolean = true): Observable<ApiResponse<Rdv[]>> {
    const query = showMyEvents ? `?showMyEvents=true` : '';
    return this.http.post<ApiResponse<Rdv[]>>(`${this.baseUrl}/get/calendar-events${query}`, param);
  }

  delete(type: RdvType, id: string, callback?: () => void) {
    this.reactiveService.call(this.http.delete<ApiResponse<boolean>>(`${this.baseUrl}/${type}/delete/${id}`), callback, true);
  }

  updateStatus(type: RdvType, request: RdvStatusRequest, callback?: () => void) {
    this.reactiveService.call(this.http.put<ApiResponse<boolean>>(`${this.baseUrl}/${type}/update-status`, request),
      callback, true);
  }

  deleteEvent(type: RdvType, rdv: Rdv, callback?: () => void) {
    if (rdv?.id) {
      this.dialogService.confirm(
        'rdv.delete-event.title',
        this.traductor.translate(`rdv.delete-event.content`,
          {
            motif: rdv.motif,
            debut: DateService.format(rdv.debut, true),
            fin: DateService.format(rdv.fin, true)
          }),
        () => this.delete(type, rdv.id, callback)
      );
    }
  }

  editStatus(rdv: Rdv, status: StatusRdv, type: RdvType, callback?: () => void) {
    if (rdv?.id) {
      this.openCommentDialog(rdv as Rdv, status)
        .pipe(filter(comment => !ObjectUtility.isNullOrUndefined(comment)))
        .subscribe(comment => this.updateStatus(type, { id: rdv.id, status, commentaire: comment }, callback));
    }
  }

  translateRdv(rdv: Rdv): string {
    if (rdv) {
      const isMedecin = this.userService.medecinIsConnected();
      return this.traductor.translate('rdv.format-rdv', {
        userName: UserService.getFullName(isMedecin ? rdv.patient : rdv.medecin?.utilisateur),
        prestation: `${rdv.motif} (${ObjectUtility.formatPrice(rdv.tarif)})`,
        debut: DateService.format(rdv.debut, true),
        fin: DateService.format(rdv.fin, true)
      });
    }
    return '';
  }

  openRdvDialog(medecinUser: Utilisateur, patient: Patient, rdv?: Rdv, date?: Date, callback?: () => void) {
    this.dialogService.open(RdvDialogComponent, {
      disableClose: true,
      data: {
        content: { medecinUser, patient, date, rdv }
      }
    }).closed.pipe(take(1), filter(res => !!res?.content && !!callback))
      .subscribe(() => callback());
  }

  openDetailsDialog(rdv?: Rdv, callback?: () => void) {
    this.dialogService.open(RdvDetailComponent, {
      data: {
        content: rdv
      }
    }).closed.pipe(take(1), filter(res => !!res?.content))
      .subscribe(value => {
        if (value.content === true && callback) {
          // Status changé
          callback();
        } else {
          // Modification
          this.openRdvDialog(null, null, rdv, null, callback);
        }
      });
  }

  openAffectationDialog(rdv?: Rdv, callback?: () => void) {
    this.dialogService.open(RdvMedecinDialogComponent, {
      data: { content: rdv }
    }).closed.pipe(take(1), filter(res => !!res?.content && !!callback))
      .subscribe(() => callback());
  }

  private openCommentDialog(rdv: Rdv, status: StatusRdv) {
    return this.dialogService.openCommentDialog({
      type: status === StatusRdv.ACCEPTED ? CommentType.HIDE : CommentType.REQUIRED,
      validationLabelKey: 'shared.confirmer',
      message: this.traductor.translate(`rdv.message-comment`, { action: this.traductor.translate(`rdv.action.${status}`).toString().toLowerCase() })
        + this.translateRdv(rdv)
    });
  }

  static formatLabel(rdv: Rdv): string {
    if (rdv?.prestation?.libelle?.length) {
      return rdv.prestation.libelle;
    } else if (rdv?.motif?.length) {
      return rdv.motif;
    } else if (rdv?.patient) {
      return rdv.patient?.nom;
    } else if (rdv?.lieu) {
      return rdv.lieu;
    } else if (rdv?.debut && rdv?.fin) {
      return `${DateService.formatJS(rdv.debut, 'HH:mm')} - ${DateService.formatJS(rdv.fin, 'HH:mm')}`;
    }
    return '';
  }

  public formatRow(rdv: Rdv): string {
    if (!rdv) {
      return '';
    }

    let info = `<div class="full-width">
                  <div class="text-info font-bold">${DateService.format(rdv.debut, true)} au ${DateService.format(rdv.fin, true)}</div>`;

    if (rdv?.patient) {
      info += `<div class="font-bold">${UserService.getFullName(rdv.patient)}</div>`;
    }

    const text = [];
    if (rdv?.motif?.length) {
      text.push(rdv.motif);
    }
    if (rdv?.lieu?.length) {
      text.push(rdv.lieu);
    }
    if (text.length) {
      info += `<div class="font-bold">${text.join(' - ')}</div>`;
    }
    return `${info}<div class="text-warn font-bold">${this.traductor.translate(`rdv.etat.${rdv.status}`)}</div></div>`;
  }

  rdvRequestIsValid(rdvRequest: Partial<Rdv>, checkPatient: boolean = true): boolean {
    return !!rdvRequest.debut && !!rdvRequest.prestation?.id && (!checkPatient || !!rdvRequest.patient?.id);
  }

  bookRdv(rdvRequest: Partial<Rdv>, callback?: (response: ApiResponse<Rdv>) => void) {
    if (this.rdvRequestIsValid(rdvRequest)) {
      const rdv: Partial<Rdv> = {
        debut: rdvRequest.debut,
        medecin: rdvRequest.medecin ? { ...rdvRequest.medecin, utilisateur: null, specialites: null } : null,
        prestation: rdvRequest.prestation,
        status: StatusRdv.PENDING,
        patient: rdvRequest.patient
      };
      this.reactiveService.call(this.book(rdv), (response: ApiResponse<Rdv>) => {
        this.notificationService.success('rdv.booking-success');
        if (callback) {
          callback(response);
        }
      });
    }
  }

}
